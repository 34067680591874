import utility from '@/common/utility'
import auth from '@/common/auth'

export default class MaterialListModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.materialListGuid
      this.materialListGuid = props.materialListGuid
      this.materialListId = props.materialListId
      this.parentId = props.parentId
      this.materialListCode = props.materialListCode
      this.materialListName = props.materialListName
      this.unit = props.unit
      this.num = props.num
      this.tenderGuid = props.tenderGuid
      this.sortId = props.sortId
      this.children = props.children
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.materialListGuid) this.materialListGuid = ''
    if (!this.materialListId) this.materialListId = 0
    if (!this.parentId) this.parentId = 0
    if (!this.materialListCode) this.materialListCode = ''
    if (!this.materialListName) this.materialListName = ''
    if (!this.unit) this.unit = ''
    if (!this.num) this.num = 0
    if (!this.tenderGuid) this.tenderGuid = ''
    if (!this.sortId) this.sortId = 0
    if (!this.children) this.children = []
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
  }

  generatePrimaryKey () {
    this.materialListGuid = utility.getUuid()
    this.resourceId = this.materialListGuid
  }
}
