<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="materialListModel"
    label-width="100px"
    >
    <el-form-item label="编号：" prop="materialListCode">
      <el-input
        type="text"
        clearable
        v-model="materialListModel.materialListCode" />
    </el-form-item>
    <el-form-item label="名称：" prop="materialListName">
      <el-input
        type="text"
        clearable
        v-model="materialListModel.materialListName" />
    </el-form-item>
    <el-form-item label="ID：" prop="materialListId">
      <el-input-number
        v-model="materialListModel.materialListId"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
    <el-form-item label="单位：" prop="unit">
      <el-input
        type="text"
        v-model="materialListModel.unit" />
    </el-form-item>
    <el-form-item label="数量：" prop="num">
      <el-input
        type="text"
        v-model="materialListModel.num" />
    </el-form-item>
    <el-form-item label="父节点：" prop="parentId">
      <my-select-tree
        v-if="nodes[0].children && nodes[0].children.length"
        v-model="materialListModel.parentId"
        nodeKey="materialListId"
        labelKey="materialListName"
        :nodes="nodes"
        :props="props">
      </my-select-tree>
    </el-form-item>
    <el-form-item label="标段：" prop="tenderGuid">
      <el-select v-model="materialListModel.tenderGuid" clearable>
        <el-option
          v-for="tender in tenders"
          :key="tender.tenderGuid"
          :label="tender.tenderName"
          :value="tender.tenderGuid">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="排序：" prop="sortId">
      <el-input-number
        v-model="materialListModel.sortId"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
  </el-form>
</template>

<script>
import MaterialListModel from '@/model/MaterialListModel'
import materialListService from '@/services/materialListService'
import MySelectTree from '@/components/MyTree/MySelectTree'
import tenderService from '@/services/tenderService'
import auth from '@/common/auth'

export default {
  name: 'MaterialListForm',
  components: {
    MySelectTree
  },
  props: {
    materialListModel: {
      type: MaterialListModel,
      default () {
        return new MaterialListModel()
      }
    }
  },
  data () {
    return {
      rules: {
        materialListName: [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur'
          }
        ],
        staffName: [
          {
            required: true,
            message: '请输入姓名',
            trigger: 'blur'
          }
        ],
        companyGuid: [
          {
            required: true,
            message: '请选择所属公司',
            trigger: 'blur'
          }
        ],
        sortId: [
          {
            required: true,
            message: '请输入排序',
            trigger: 'blur'
          }
        ]
      },
      tenders: [],
      tenderProps: {
        label: 'tenderName',
        children: 'children'
      },
      nodes: [
        new MaterialListModel({ materialListId: 0, materialListName: '根节点' })
      ],
      props: {
        label: 'materialListName',
        children: 'children'
      }
    }
  },
  computed: {
    tenderGuid () {
      return this.materialListModel.tenderGuid
    }
  },
  watch: {
    tenderGuid (newVal) {
      if (newVal) {
        this._getNodes()
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    },
    _getNodes () {
      materialListService.treeList(this.tenderGuid)
        .then(res => {
          if (res.data.code === 1) {
            this.$set(this.nodes[0], 'children', res.data.data)
            console.log(this.nodes)
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          window.console.log(err.request.response)
          this.$message({
            type: 'error',
            message: '加载数据错误！'
          })
        })
    },
    _getTenders () {
      tenderService.listByUser(auth.getUserInfo().userGuid)
        .then(res => {
          if (res.data.code === 1) {
            this.tenders = res.data.data
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '获取数据失败！'
          })
          console.log(err.request.response)
        })
    }
  },
  created () {
    this._getNodes()
    this._getTenders()
  }
}
</script>
